<template>
    <small>
        <font-awesome-icon :icon="role.icon" v-if="shouldDisplay" v-b-tooltip.hover="tooltip" class="ml-1"/>
    </small>
</template>

<script>
    import {ROLES_AVAILABLE} from '@/util/wiki';
    import {isCluji}         from '@/util/auth';

    export default {
        name: "VisibilityIconWiki",
        props: {
            wikiPart: {
                type: Object,
                required: true
            }
        },
        computed: {
            role() {
                return ROLES_AVAILABLE.find(({id}) => id === this.wikiPart.visible);
            },
            tooltip() {
                switch (this.role.id) {
                    case 'ROLE_USER':
                        return 'Visible par les utilisateurs connectés';
                    case 'ROLE_ANY_ASSO':
                        return "Visible par les membres d'une association";
                    case 'ROLE_CLUJI':
                        return 'Visible par les Clujistes';
                    case 'ROLE_BUREAU':
                        return 'Visible par le bureau';
                    default:
                        return 'Public';
                }
            },
            shouldDisplay() {
                return isCluji();
            }
        }
    }
</script>

<style scoped>
    small {
        opacity: .5;
        font-size: 66%;
    }
</style>